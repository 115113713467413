var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-card', {
    staticClass: "px-5 mx-sm-5 mt-10",
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-title', [_vm._v(_vm._s(_vm.$t("Printers")))]), _c('div', {
    staticClass: "text-right"
  }), _c('v-card-title', [_c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.openDialogAddPrinter
    }
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-plus-circle ")]), _vm._v(_vm._s(_vm.$t("addPrinter")) + " ")], 1), _c('v-spacer'), _c('v-text-field', {
    attrs: {
      "append-icon": "fas fa-search",
      "label": "Search",
      "single-line": "",
      "hide-details": ""
    },
    model: {
      value: _vm.searchPrinters,
      callback: function ($$v) {
        _vm.searchPrinters = $$v;
      },
      expression: "searchPrinters"
    }
  })], 1), _c('v-card-text', [_c('v-data-table', {
    attrs: {
      "headers": _vm.headersPrinters,
      "items": _vm.printers,
      "search": _vm.searchPrinters,
      "footer-props": {
        itemsPerPageOptions: [15, 50, 100, -1]
      }
    },
    scopedSlots: _vm._u([{
      key: `item.brand`,
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('span', [_vm._v(_vm._s(item.brand.split("_").join(" ")))])];
      }
    }, {
      key: `item.model`,
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_c('span', [_vm._v(_vm._s(item.model.split("_").join(" ")))])];
      }
    }, {
      key: `item.technology`,
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_c('span', [_vm._v(_vm._s(item.technology))])];
      }
    }, {
      key: `item.build_volume`,
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_c('span', [_vm._v(_vm._s(item.build_volume.depth) + " x ")]), _c('span', [_vm._v(_vm._s(item.build_volume.width) + " x ")]), _c('span', [_vm._v(_vm._s(item.build_volume.height) + " mm")])];
      }
    }, {
      key: `item.minimumSpacing`,
      fn: function (_ref5) {
        var item = _ref5.item;
        return [_c('span', [_vm._v(_vm._s(item.minimumSpacing))])];
      }
    }, {
      key: `item.quantity`,
      fn: function (_ref6) {
        var item = _ref6.item;
        return [_c('span', [_vm._v(_vm._s(item.quantity))])];
      }
    }, {
      key: `item.actions`,
      fn: function (_ref7) {
        var item = _ref7.item;
        return [_c('v-icon', {
          staticClass: "mr-2",
          attrs: {
            "small": ""
          },
          on: {
            "click": function ($event) {
              return _vm.editPrinter(item.brand, item.model, item.technology, item.build_volume, item.quantity, item.minimumSpacing);
            }
          }
        }, [_vm._v(" fas fa-pen ")])];
      }
    }], null, true)
  })], 1)], 1), _vm.technologiesAndMaterials ? _c('printer-configuration', {
    ref: "printerConfiguration",
    attrs: {
      "new-printer": _vm.newPrinter,
      "printer": _vm.printer,
      "brands-available": _vm.brandsAvailable,
      "models-available": _vm.modelsAvailable,
      "brand-chosen": _vm.brandChosen,
      "model-chosen": _vm.modelChosen,
      "technologies-and-materials": _vm.technologiesAndMaterials
    },
    on: {
      "addSupplierPrinter": _vm.addSupplierPrinter,
      "modifySupplierPrinter": _vm.modifySupplierPrinter,
      "clearFormAddPrinter": _vm.clearFormAddPrinter,
      "getPrintersModels": function ($event) {
        return _vm.getPrintersModels($event);
      }
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }