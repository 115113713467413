<template>
  <div>
    <v-card flat class="px-5 mx-sm-5 mt-10">
      <v-card-title>{{ $t("Printers") }}</v-card-title>
      <div class="text-right"></div>
      <v-card-title>
        <v-btn
          color="primary"
          class="text-none"
          @click="openDialogAddPrinter"
        >
          <v-icon small class="mr-2">
            fas fa-plus-circle
          </v-icon>{{ $t("addPrinter") }}
        </v-btn>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="searchPrinters"
          append-icon="fas fa-search"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headersPrinters"
          :items="printers"
          :search="searchPrinters"
          :footer-props="{
            itemsPerPageOptions: [15, 50, 100, -1],
          }"
        >
          <template v-slot:[`item.brand`]="{ item }">
            <span>{{ item.brand.split("_").join(" ") }}</span>
          </template>
          <template v-slot:[`item.model`]="{ item }">
            <span>{{ item.model.split("_").join(" ") }}</span>
          </template>
          <template v-slot:[`item.technology`]="{ item }">
            <span>{{ item.technology }}</span>
          </template>
          <template v-slot:[`item.build_volume`]="{ item }">
            <span>{{ item.build_volume.depth }} x </span>
            <span>{{ item.build_volume.width }} x </span>
            <span>{{ item.build_volume.height }} mm</span>
          </template>
          <template v-slot:[`item.minimumSpacing`]="{ item }">
            <span>{{ item.minimumSpacing }}</span>
          </template>
          <template v-slot:[`item.quantity`]="{ item }">
            <span>{{ item.quantity }}</span>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon
              small
              class="mr-2"
              @click="
                editPrinter(
                  item.brand,
                  item.model,
                  item.technology,
                  item.build_volume,
                  item.quantity,
                  item.minimumSpacing
                )
              "
            >
              fas fa-pen
            </v-icon>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <!-- COMPONENT DIALOG TO ADD AND MODIFY -->
    <!-- COMPONENT DIALOG TO ADD AND MODIFY -->
    <!-- COMPONENT DIALOG TO ADD AND MODIFY -->
    <!-- COMPONENT DIALOG TO ADD AND MODIFY -->
    <printer-configuration
      v-if="technologiesAndMaterials"
      ref="printerConfiguration"
      :new-printer="newPrinter"
      :printer="printer"
      :brands-available="brandsAvailable"
      :models-available="modelsAvailable"
      :brand-chosen="brandChosen"
      :model-chosen="modelChosen"
      :technologies-and-materials="technologiesAndMaterials"
      @addSupplierPrinter="addSupplierPrinter"
      @modifySupplierPrinter="modifySupplierPrinter"
      @clearFormAddPrinter="clearFormAddPrinter"
      @getPrintersModels="getPrintersModels($event)"
    ></printer-configuration>
  </div>
</template>

<script>
import {
  EventBus,
  ApiErrorParser,
  PrinterConfiguration,
} from '@cloudmanufacturingtechnologies/portal-components';

const i18nData = require('./pageSupplierPrinters.i18n.json');

export default {
  name: 'PageSupplierPrinters',
  components: { PrinterConfiguration },
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  data() {
    return {
      searchPrinters: '',
      newPrinter: new this.$BcmModel.Printer(),
      printers: [],
      headersPrinters: [],
      printer: null,
      dialogAddPrinter: false,
      dialogEditPrinter: false,
      printersBrands: null,
      printersModels: null,
      modelsAvailable: [],
      brandsAvailable: [],
      brandChosen: false,
      modelChosen: false,
      technologies: null,
      materials: null,
      technologiesAndMaterials: null
    };
  },
  created() {
    this.getAllTechnologiesAndMaterials();
    /**
     * GET SUPPLIER PRINTERS
     */
    this.getSupplierPrinters();
    /**
     * GET PRINTERS BRANDS
     */
    this.getPrintersBrands();
    this.newPrinter.build_volume = new this.$BcmModel.PrinterBuildVolume();
  },
  mounted() {
    this.headersPrinters = [
      { text: this.$t('brand'), value: 'brand' },
      { text: this.$t('model'), value: 'model' },
      { text: this.$t('technology'), value: 'technology' },
      {
        text: this.$t('buildVolume'),
        value: 'build_volume',
        align: 'center',
      },
      {
        text: this.$t('minimumSpacing'),
        value: 'minimumSpacing',
        align: 'center',
      },
      { text: this.$t('quantity'), value: 'quantity', align: 'center' },
      {
        text: this.$t('actions'),
        value: 'actions',
        sortable: false,
        align: 'center',
      },
    ];
  },
  methods: {
    getAllTechnologiesAndMaterials() {
      this.$apiInstance.getAllTechnologiesAndMaterialsCompatibilities().then(data => {
        this.technologiesAndMaterials = data;
      });
    },
    /**
     * GET SUPPLIER PRINTERS
     */
    getSupplierPrinters() {
      this.$apiInstance
        .getSupplierPrinters(this.$route.params.supplierUUID)
        .then(
          (printersData) => {
            this.printers = printersData;
          },
          (error) => {
            /**
             * ERROR GET PRINTER
             */
            ApiErrorParser.parse(error);
          }
        );
    },
    /**
     * ADD SUPPLIER PRINTER
     */
    addSupplierPrinter() {
      if (
        this.$refs.printerConfiguration.printerBrandFormIsValid() &&
        this.$refs.printerConfiguration.printerModelFormIsValid() &&
        this.$refs.printerConfiguration.printerBuildVolumeFormIsValid()
      ) {
        this.newPrinter.build_volume = {
          depth: parseInt(this.newPrinter.build_volume.depth),
          width: parseInt(this.newPrinter.build_volume.width),
          height: parseInt(this.newPrinter.build_volume.height),
        };
        const printerBrand = this.newPrinter.brand
          .trim()
          .toUpperCase()
          .replace(/\s+/g, '_');
        const printerModel = this.newPrinter.model
          .trim()
          .toUpperCase()
          .replace(/\s+/g, '_');
        const addSupplierPrinterBody = new this.$BcmModel.AddSupplierPrinterBody(
          printerBrand,
          printerModel,
          this.newPrinter.technology,
          this.newPrinter.build_volume,
          parseInt(this.newPrinter.quantity),
          parseInt(this.newPrinter.minimumSpacing)
        );
        this.$apiInstance
          .addSupplierPrinter(
            this.$route.params.supplierUUID,
            addSupplierPrinterBody
          )
          .then(
            () => {
              this.$notification.notify('SUCCESS',this.$t('printerSuccessfullyAdded'));
            },
            (error) => {
              /**
               * ERROR ADD PRINTER
               */
              this.$notification.notify('DANGER',this.$t(ApiErrorParser.parse(error)));
            }
          )
          .finally(() => {
            this.getSupplierPrinters();
            this.getPrintersBrands();
            this.clearFormAddPrinter();
          });
      }
    },

    /**
     * GET SUPPLIER PRINTER FOR EDIT
     */
    editPrinter(
      brand,
      model,
      technology,
      buildVolume,
      quantity,
      minimumSpacing
    ) {
      this.printer = {
        brand: brand,
        model: model,
        technology: technology,
        build_volume: {
          depth: buildVolume.depth,
          width: buildVolume.width,
          height: buildVolume.height,
        },
        quantity: quantity,
        minimumSpacing: minimumSpacing,
      };
      this.openDialogEditPrinter();
    },
    /**
     * MODIFY SUPPLIER PRINTER
     */
    modifySupplierPrinter() {
      if (
        this.$refs.printerConfiguration.editPrinterFormIsValid() &&
        this.$refs.printerConfiguration.editPrinterBuildVolumeFormIsValid()
      ) {
        this.printer.build_volume = {
          depth: parseInt(this.printer.build_volume.depth),
          width: parseInt(this.printer.build_volume.width),
          height: parseInt(this.printer.build_volume.height),
        };
        const modifySupplierPrinterBody = new this.$BcmModel.ModifySupplierPrinterBody(
          this.printer.build_volume,
          parseInt(this.printer.quantity),
          parseInt(this.printer.minimumSpacing)
        );
        this.$apiInstance
          .modifySupplierPrinter(
            this.$route.params.supplierUUID,
            this.printer.brand,
            this.printer.model,
            modifySupplierPrinterBody
          )
          .then(
            () => {
              this.getSupplierPrinters();
              this.$notification.notify('SUCCESS',this.$t('printerSuccessfullyModified'));
            },
            (error) => {
              /**
               * ERROR EDIT PRINTER
               */
              this.$notification.notify('DANGER',this.$t(ApiErrorParser.parse(error)));
            }
          )
          .finally(() => {
            this.closeDialogEditPrinter();
          });
      }
    },
    /**
     * GET ALL PRINTERS BRANDS
     */
    getPrintersBrands() {
      this.$apiInstance.getPrintersBrands().then(
        (printersBrandsData) => {
          this.printersBrands = printersBrandsData;
          for (let i = 0; i < this.printersBrands.length; i++) {
            this.brandsAvailable.push(
              this.printersBrands[i].split('_').join(' ')
            );
          }
        },
        (error) => {
          ApiErrorParser.parse(error);
        }
      );
    },
    /**
     * GET ALL PRINTERS MODELS AFTER SELECTED THE BRAND
     */
    getPrintersModels(brandName) {
      this.modelsAvailable = [];
      this.brandChosen = false;
      this.modelChosen = false;
      this.$refs.printerConfiguration.printerModelFormNeedReset();
      this.$refs.printerConfiguration.printerBuildVolumeFormNeedReset();
      this.$refs.printerConfiguration.printerBuildVolumeFormNeedResetValidation();
      this.$apiInstance
        .getPrintersModels(brandName.replace(/\s+/g, '_'))
        .then(
          (printersModelsData) => {
            this.printersModels = printersModelsData;
            for (let i = 0; i < this.printersModels.length; i++) {
              this.modelsAvailable.push(
                this.printersModels[i].model.split('_').join(' ')
              );
            }
          },
          (error) => {
            ApiErrorParser.parse(error);
          }
        )
        .finally(() => {
          this.brandChosen = true;
        });
    },
    /**
     * CLEAR DATA WHEN CANCEL DIALOG
     */
    clearFormAddPrinter() {
      this.$refs.printerConfiguration.printerModelFormNeedReset();
      this.$refs.printerConfiguration.printerBrandFormNeedReset();
      this.$refs.printerConfiguration.printerBuildVolumeFormNeedReset();
      this.brandChosen = false;
      this.modelChosen = false;
      EventBus.$emit('closeDialogAddPrinter');
    },
    /**
     * EVENT TO OPEN DIALOGS
     */
    closeDialogEditPrinter() {
      EventBus.$emit('closeDialogEditPrinter');
    },
    openDialogAddPrinter() {
      EventBus.$emit('openDialogAddPrinter');
    },
    openDialogEditPrinter() {
      EventBus.$emit('openDialogEditPrinter');
    },
  },
};
</script>
